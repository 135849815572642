import { addDays } from 'date-fns';
import { makeExpirationDate } from "./makers";
import { fromDatestampObject, makeDatestampObject } from "../time";
export const fromExpirationDate = (expirationDate, now) => {
  if (expirationDate == null || expirationDate.type === 'none') return null;
  const newDate = addDays(now, expirationDate.days);
  return makeDatestampObject(newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate());
};
export const fromDatestampObjectToExpirationDate = (datestampObject, createdOn) => {
  if (datestampObject === null) return makeExpirationDate();
  const oneDay = 1000 * 60 * 60 * 24;
  const daysBetween = Math.round((fromDatestampObject(datestampObject).getTime() - createdOn) / oneDay);
  return makeExpirationDate(daysBetween);
};