function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { routes } from "../../constants";
import { relationshipSummaryModel, timeModel } from "../../models";
import { RelationshipProgressStatus } from "../../models/relationship";
import { COBStep } from "../../client-flow/clientOb/ClientOnboarding.utils";
import { Link } from 'react-router-dom';
import { Intent } from "../../infra/access-control";
import { fromDatestampObject } from "../../models/time";
import { formatShorterUSDate } from "../../utils";
const fiveDays = 1000 * 60 * 60 * 24 * 5;
const tenDays = fiveDays * 2;
const didTimePassed = (timestamp, time) => {
  const timePassed = Date.now() - timestamp;
  return timePassed > time;
};
const statusToSeverity = {
  microDepositStarted: 10,
  microDepositFailed: 9,
  paymentMethod: 8,
  reviewed: 7,
  sent: 6,
  expired: 5
};
const proposalStatusDefinition = isVendor => {
  return {
    sent: {
      severity: statusToSeverity.sent,
      statusText: isVendor ? 'Proposal sent to client' : 'Sent',
      statusSkin: 'standard'
    },
    reviewed: {
      severity: statusToSeverity.reviewed,
      statusText: isVendor ? 'Reviewed by client' : 'Reviewed',
      statusSkin: 'standard'
    },
    microDepositFailed: {
      severity: statusToSeverity.microDepositFailed,
      statusText: 'Micro deposit failed',
      statusSkin: 'error'
    },
    microDepositStarted: {
      severity: statusToSeverity.microDepositStarted,
      statusText: 'Initiated micro deposit',
      statusSkin: 'error'
    },
    paymentMethod: {
      severity: statusToSeverity.paymentMethod,
      statusText: 'Connected payment method',
      statusSkin: 'standard'
    },
    expired: {
      severity: statusToSeverity.expired,
      statusText: 'Expired',
      statusSkin: 'error'
    }
  };
};
export const vendorStatusesDefinition = proposalStatusDefinition(true);
export const clientStatusesDefinition = proposalStatusDefinition(false);
const getIsStatusMicroDepositFailed = agreement => (agreement === null || agreement === void 0 ? void 0 : agreement.progressStatus) === RelationshipProgressStatus.MicroDeposit && didTimePassed(agreement === null || agreement === void 0 ? void 0 : agreement.progressStatusTimestamp, tenDays);
const getMicroDepositLastUpdateText = (progressStatusTimestamp, isStatusMicroDepositFailed) => {
  if (isStatusMicroDepositFailed) {
    return progressStatusTimestamp ? timeModel.formatTimeAgo(progressStatusTimestamp + tenDays) : '';
  }
  const didFiveDaysPassed = didTimePassed(progressStatusTimestamp, fiveDays);
  if (didFiveDaysPassed) {
    return 'Expiring soon';
  } else {
    return null;
  }
};

//This is temporary until backend returns the right response
function getMicroDepositMappedStatus(agreement) {
  const isStatusMicroDepositFailed = getIsStatusMicroDepositFailed(agreement);
  return isStatusMicroDepositFailed ? 'microDepositFailed' : 'microDepositStarted';
}

// This is temporary until backend returns the right response
export const getAgreementStatus = agreement => {
  const {
    progressStatus,
    progressStatusTimestamp
  } = agreement;
  const shouldOverrideOriginalStatus = getIsStatusMicroDepositFailed(agreement);
  if (shouldOverrideOriginalStatus) {
    const mdDate = new Date(progressStatusTimestamp);
    mdDate.setDate(mdDate.getDate() + 10);
    const newTimestamp = mdDate.getTime();
    return {
      progressStatus: RelationshipProgressStatus.MicroDepositFailed,
      progressStatusTimestamp: newTimestamp
    };
  }
  return {
    progressStatus,
    progressStatusTimestamp
  };
};
export const getSentByUIData = agreement => {
  const {
    seller
  } = agreement;
  const sellerDisplayValue = "".concat(seller.firstName, " ").concat(seller.lastName);
  return sellerDisplayValue;
};
export const getStatusUIData = (agreement, isVendor) => {
  const {
    progressStatusTimestamp,
    expirationDate
  } = agreement;
  const progressStatus = agreement.progressStatus;
  const statusDefinition = isVendor ? vendorStatusesDefinition : clientStatusesDefinition;
  let lastUpdateText = expirationDate ? "Expires on ".concat(formatShorterUSDate(fromDatestampObject(expirationDate))) : progressStatusTimestamp ? timeModel.formatTimeAgo(progressStatusTimestamp) : '';
  let viewableStatus;
  switch (progressStatus) {
    case RelationshipProgressStatus.MicroDeposit:
      {
        viewableStatus = getMicroDepositMappedStatus(agreement);
        lastUpdateText = getMicroDepositLastUpdateText(progressStatusTimestamp, viewableStatus === 'microDepositFailed') || lastUpdateText;
        break;
      }
    case RelationshipProgressStatus.Expired:
      {
        viewableStatus = progressStatus;
        lastUpdateText = "Expired on ".concat(formatShorterUSDate(fromDatestampObject(expirationDate)));
        break;
      }
    default:
      viewableStatus = progressStatus;
      break;
  }
  return _objectSpread(_objectSpread({}, statusDefinition[viewableStatus]), {}, {
    lastUpdateText,
    viewableStatus
  });
};
const sortByCompanyName = function sortByCompanyName() {
  let isVendor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return (a, b) => {
    const nameA = relationshipSummaryModel.opposingPartyName(a, isVendor).toLowerCase();
    const nameB = relationshipSummaryModel.opposingPartyName(b, isVendor).toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };
};
const sortById = (a, b) => a.id < b.id ? -1 : 1;
const sortBySentDate = (a, b) => b.createdOn.getTime() - a.createdOn.getTime();
const sortBySentBy = (a, b) => {
  const sellerA = getSentByUIData(a);
  const sellerB = getSentByUIData(b);
  if (sellerA === sellerB) {
    return 0;
  } else if (sellerA > sellerB) {
    return 1;
  } else {
    return -1;
  }
};
const sortByStatus = (a, b, isVendor) => {
  const {
    severity: severityA
  } = getStatusUIData(a, isVendor);
  const {
    severity: severityB
  } = getStatusUIData(b, isVendor);
  if (severityA < severityB) {
    return -1;
  }
  if (severityA > severityB) {
    return 1;
  }
  return 0;
};
const sortByStatusUpdate = (a, b) => {
  if (!a.progressStatusTimestamp && !b.progressStatusTimestamp) {
    return 0;
  }
  if (!a.progressStatusTimestamp) {
    return 1;
  }
  if (!b.progressStatusTimestamp) {
    return -1;
  }
  return b.progressStatusTimestamp - a.progressStatusTimestamp;
};
export const sortFunctions = {
  0: _ref => {
    let {
      isAscending,
      isVendor
    } = _ref;
    return (a, b) => {
      const retval = sortByCompanyName(isVendor)(a, b) || sortById(a, b);
      return isAscending ? -retval : retval;
    };
  },
  1: _ref2 => {
    let {
      isAscending
    } = _ref2;
    return (a, b) => {
      const retval = sortBySentDate(a, b) || sortById(a, b);
      return isAscending ? -retval : retval;
    };
  },
  2: _ref3 => {
    let {
      isAscending
    } = _ref3;
    return (a, b) => {
      const sentBySort = sortBySentBy(a, b);
      return isAscending ? -sentBySort : sentBySort;
    };
  },
  3: _ref4 => {
    let {
      isAscending,
      isVendor
    } = _ref4;
    return (a, b) => {
      const statusSort = sortByStatus(a, b, isVendor);
      return (isAscending ? -statusSort : statusSort) || sortByStatusUpdate(a, b) || sortById(a, b);
    };
  },
  4: _ref5 => {
    let {
      isAscending
    } = _ref5;
    return (a, b) => {
      const retval = sortByStatusUpdate(a, b) || sortById(a, b);
      return isAscending ? -retval : retval;
    };
  }
};

//TODO - this is temporary until microDeposit status is gone in favor of the specific ones
export function getProposalsStatusCounters(agreements, isVendor) {
  return agreements.reduce((acc, agreement) => {
    const statusUI = getStatusUIData(agreement, isVendor);
    acc[statusUI.viewableStatus] = (acc[statusUI.viewableStatus] || 0) + 1;
    return acc;
  }, {});
}
export function getRowAndMainAction(_ref6) {
  let {
    proposal,
    isVendor,
    heapId,
    history,
    openClientPreview
  } = _ref6;
  if (isVendor) {
    return {
      rowProps: {
        as: 'button',
        onClick: openClientPreview
      },
      mainAction: {
        buttonText: 'Preview',
        onClickButton: openClientPreview
      }
    };
  } else {
    const clientAgreementLink = routes.clientOnboarding(proposal.id, getIsStatusMicroDepositFailed(proposal) ? COBStep.paymentMethod : undefined);
    return {
      rowProps: {
        as: Link,
        to: clientAgreementLink
      },
      mainAction: {
        buttonText: 'View',
        intent: Intent.ClientViewProposal,
        btnHeapId: "".concat(heapId, "-view-btn"),
        onClickButton: () => history.push(clientAgreementLink)
      }
    };
  }
}