import { timeModel } from '.';
export class InvoiceDraftDatesModel {
  static fromDTO(dto) {
    return new InvoiceDraftDatesModel({
      dates: dto.dates.map(timeModel.fromDatestampObject),
      earliestBillableDate: timeModel.fromDatestampObject(dto.earliestBillableDate)
    });
  }
  static isInvoiceDraftDatesDTO(obj) {
    return obj && obj.dates && obj.earliestBillableDate;
  }
  constructor(data) {
    this.data = data;
  }
  dates() {
    return this.data.dates;
  }
  earliestBillableDate() {
    return this.data.earliestBillableDate;
  }
}